import React from 'react'

const ITEMS = [
    {
        title: "Preluare automată a cererilor",
        desc: "Prin modulul suplimentar PROFIL PRIVAT, solicitanții pot să completeze cererile necesare pentru obținerea certificatului de urbanism și a autorizației de construire"
    },
    {
        title: "Urmărirea fluxului de avizare",
        desc: "Atât cererile de avizare cât și avizele prevăzute de lege pot fi generate și urmărite automat orin intermediul serviciului nostru"
    },
    {
        title: "Urmărirea termenelor legale",
        desc: "Folosiți e-urban.ro și aveți o evidență clară și în timp real a termelor fiecărui document. Evitați astfel o serie de sitații neplăcute"
    },
    {
        title: "Eliberați automat certificate și autorizații",
        desc: "Scăpați de munca plicitistoare și repetitivă de redactare a documentele. E-urban.ro colecteză de-a lungul procesului toate datele necesare pentru a le genera automat!"
    },
    {
        title: "Nu ratați nici un termen de raportare căre I.S.C.",
        desc: "Absolut toate rapoartele sintetice lunare solicitate de Inspectia de stat în construcții sunt generate automat, la zi, în orice moment"
    },
    {
        title: "Gestionați toate documentele electronic",
        desc: "Prin facilitațile de upload ale soluției noastre, oricând veți avea nevoie să consultați un document nu mai mergeți la biblioraft! Le aveți toate într-un singur loc, la un click distanță!"
    }
]

export default function Servicii() {
  return (
    <section className="py-5 xs:py-0" >
    <div className="container px-4 mx-auto">
      <div className="max-w-5xl mx-auto text-center mb-16 md:mb-14">
        {/* <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-blue-500 bg-blue-100 font-medium uppercase rounded-full shadow-sm">CUM FUNCȚIONEAZĂ</span> */}
        <h2 className="mb-4 text-3xl md:text-4xl leading-tight font-bold tracking-tighter">Ce este e-urban.ro ?</h2>
        <p className="text-2xs md:text-lg  xs:text-xs text-blue-900 font-medium">Serviciul <strong>e-urban.ro</strong> se adresează exclusiv administrației publice locale, mai exact compartimentelor de urbanism, care procesează și eliberează certificate de urbanism și autorizații de construire/desfințare </p>
      </div>
      <div className="flex flex-wrap -mx-4">
        {ITEMS.map( (e,i) => (
        <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 "  key={i}>
        <div className="relative h-full px-8 pt-12 pb-8 bg-coolGray-50 rounded-md shadow-md bg-sky-50">
          <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-500 rounded-full text-xl font-semibold text-white">{i+1}</div>
          </div>
          <h3 className="md:w-64 mb-1 text-lg md:text-sm font-bold text-left">{e.title}</h3>
          <p className="text-coolGray-500 text-sm ">{e.desc}</p>
        </div>
      </div>
        ))}

        
      </div>
    </div>
    </section>
  )
}

import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { sendPageview } from '../GDPR/ga-utils'
import Loading from '../Loading'


export default function Homepage() {



  return (
    <div className="py-0 md:py-10">
      
    <div className="container px-4 mx-auto my-2 md:my-20" >
      <div className="flex flex-wrap xl:items-center -mx-4">
        <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">  
          {/* <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-yellow-100 bg-blue-800 uppercase rounded-9xl">e-urban.ro</span> */}
          <h1 className="mb-6 text-3xl sm:text-4xl md:text:5xl lg:text-6xl leading-tight font-bold tracking-tight">
            Serviciu online smart <br/>
            pentru managementul fluxurilor de urbanism
          </h1>
         
          <p className="mb-8 text-lg md:text-xl text-coolGray-500 font-medium">
                Am ascultat, am analizat și ne-am pus la treabă.... <br/>
                Rezultatul ne-a depășit așteptările!
            </p>
          <div className="flex flex-wrap">
            <Link href = "/#contact" scroll={false}>
            <div className="w-full md:w-full py-1 md:py-0 md:mr-4"><div className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-white font-medium text-center bg-blue-800 hover:bg-blue-600 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-50 border border-blue-500 rounded-md shadow-sm" >Contactează-ne și solicită o demonstrație</div></div>
            </Link>
            {/* <div className="w-full md:w-auto py-1 md:py-0"><a className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-coolGray-800 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-200 focus:ring-opacity-50 border border-coolGray-200 rounded-md shadow-sm" href="#">Autentifică-te</a></div> */}
          </div>
         
        </div>
        <div className="w-full md:w-1/2 px-4 block">
          {/* <div className=" mx-auto md:mr-0 max-w-max block"> */}
            {/* <Image className="absolute z-10 -left-14 -top-12 w-28 md:w-auto text-yellow-400" src="/elements/dots3-blue.svg" alt="dots" width={100} height={10}/> */}
            {/* <img className="absolute z-10 -right-3 -bottom-10 w-28 md:w-auto text-blue-500" src="./elements/dots3-blue.svg" alt="" /> */}
            <Image 
                src="/_img/teaser.png" 
                alt="teaser image" 
                layout="responsive"
                sizes = ""
                width={600} 
                height={315} 
                placeholder="blur"
                blurDataURL='./_img/pixel.jpg'
              />
          {/* </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}

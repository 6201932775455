import Head from 'next/head'
import Image from 'next/image'
import MainLayout from '../components/layouts/MainLayout'
import SEO from '../components/SEO'


export default function Home() {
  return (
    <div>
      <SEO
       url = "https://e-urban.ro"
       type= "Website"
       title = "Serviciul web e-urban.ro"
       description = "Serviciul web smart expert e-urban.ro pentru procesarea completă a fluxurilor de urbanism"
       image = "./_img/fb_logo.png"
       />
      {/* <Head>
        <title>e-urban.ro</title>
        <meta name="description" content="Serviciul web smart expert e-urban.ro pentru procesarea completă a fluxurilor de urbanism" />
        <link rel="icon" href="/favicon.ico" />
      </Head> */}

    <MainLayout/>
    </div>
  )
}

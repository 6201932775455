import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";


const socialTags = ({
  type,
  url,
  title = "",
  author,
  description = "",
  image,
  createdAt,
  updatedAt,
}) => {
  const metaTags = [
    { name: "twitter:card", content: "summary_small_image" },
    {
      name: "twitter:site",
      content: ""
    },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    {
      name: "twitter:creator",
      content: author
    },
    { name: "twitter:image:src", content: image },
    { name: "twitter:card", content: "summary_small_image" },
    { name: "og:title", content: title },
    { name: "og:type", content: type || "Website" },
    { name: "og:url", content: url },
    { name: "og:image", content: image },
    { name: "og:description", content: description },
    {
      name: "og:site_name",
      content: url,
    },
    {
      name: "og:published_time",
      content: createdAt || new Date().toISOString(),
    },
    {
      name: "og:modified_time",
      content: updatedAt || new Date().toISOString(),
    },
  ];

  return metaTags;
};

const SEO = (props) => {
  const { title, description, image } = props;
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="keywords" content="certificate, autorizatii, serviciu online, aplicatie web, Arad, primarie, primarii, administratie publica,
      digitalizare,
      certificat de urbanism, autorizatie de constructie, construire desfintare,  
      aviz urbanism, aplicatie autorizatii, program autorizatii, autorizatie online, certificat urbanism online" />
      <meta name="robots" content="index, follow" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="Romanian" />
      <meta name="revisit-after" content="30 days" />
      <meta name="author" content="Maftei Gabriel Claudiu PFA" />

{/* pwa stuff */}
      <link rel="icon" href="/favicon.ico" />
      {/* <link rel='manifest' href='/manifest.json' /> */}
      <meta name='application-name' content={title} />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
      <meta name='apple-mobile-web-app-title' content={title} />
      <meta name='description' content={description} />
      <meta name='format-detection' content='telephone=no' />
      <meta name='mobile-web-app-capable' content='yes' />
      <meta name='theme-color' content='#FFFFFF' />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />

{/* meta stuff (facebook, instagram, twitter)       */}

      {socialTags(props).map(({ name, content }) => {
        return <meta key={name} name={name} content={content} />;
      })}
    </Head>
  );
};



SEO.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;
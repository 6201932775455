import Head from 'next/head'
import Image from 'next/image'
import React from 'react'

const SLOGANS =   [
    "Invățăm în permanență din experiența Dvs. practică",
    "Comunicăm în grup, prin social media cu toți clienții", 
    "Punem în slujba Dvs. cele mai moderne tehnologii web"
]

export default function DespreNoi() {
    return (
        <section className="py-20 xs:py-0 overflow-hidden" >
            <div className="container px-4 mx-auto">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full md:w-2/5 px-4 mb-20 lg:mb-0">
                        <div className="max-w-md mx-auto">
                            <h2 className="mb-8 text-4xl md:text-4xl font-heading font-bold text-coolGray-900 md:leading-15 leading-tight">Serviciile noastre smart crează comunități</h2>
                            <ul className="mb-8">
                                {
                                  SLOGANS.map( (e,i) => (
                                        <li className="flex items-center mb-4" key={i}>
                                            <div className='block w-10'>
                                        <Image 
                                            className="mr-3" src="/_img/checkbox-blue.svg" 
                                            alt="check-sign" 
                                            width={50} height={50}
                                            layout="responsive"
                                        />
                                        </div>
                                        <span className="pl-4 text-lg md:text-lg font-normal text-blue-900">{e}</span>
                                    </li>
                                     ))
                                }

          
                            </ul>

                                <a className="inline-flex items-center justify-center px-7 py-3 h-14 w-full md:w-auto text-lg leading-7 text-white bg-blue-800 hover:bg-blue-600 font-medium focus:ring-2 focus:ring-blue-800 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm" href="#">Cereți cu încredere referințe oricărui client</a>
                        </div>
                    </div>
                    <div className="w-full md:w-3/5 sm:-mt-10 p-5">
                        
                            <Image
                                 src="/_img/harta.png" 
                                 width={1000} height={600} 
                                 alt="harta clienti"
                                 loading="lazy"
                                 layout="responsive"
                                 placeholder="blur"
                                 blurDataURL='./_img/pixel.jpg'
                            />
                            <p className='text-center text-xs font-heading text-gray-400'><sup>*</sup> 
                            Clienții abonați din județul Arad la diverse servicii web prestate de noi (actualizare: 01.08.2022)</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

import ga4 from 'react-ga4'


const isProduction = process.env.NODE_ENV === 'production'

export const init = (id) => ga4.initialize(id, { 
  testMode: !isProduction 
})

export const sendEvent = (name) => ga4.event('screen_view', {
  app_name: "e-urban.ro",
  screen_name: name,
})

export const sendPageview = (path) => ga4.send({ 
  hitType: 'pageview', 
  page: path 
})
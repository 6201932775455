import { useEffect } from "react";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { init } from "./ga-utils";


function GDPR() {

  const handleAcceptCookie = () => {
    console.log("emnv:", process.env)
    if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
      init(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div>
      
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText="ACCEPT"
        declineButtonText="REFUZ"
        style={{ background: "#eee", color: "#000" }}
        buttonStyle={{ background:"navy", color: "yellow", fontSize: "0.7rem", borderRadius:"0.5em" }}
        declineButtonStyle = {{background: "lightgrey", color: "black", fontSize: "0.7rem",borderRadius:"0.5em"}}
       
      >
        Acest site folosește Cookies. Vă rugăm să vă exprimați acceptul pentru folosirea lor  
      </CookieConsent>
    </div>
  );
}

export default GDPR;
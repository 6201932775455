import Head from 'next/head'
import Image from 'next/image'
import React from 'react'

const ITEMS = [
    {
        title: "Eficientizarea proceselor",
        leading: "PROCESELE GESTIONATE DE COMPARTIMENTUL DVS. DE URBANISM \
        SUNT EXTREME DE COMPLEXE",
        desc: " Atât compartimentul de urbanism, cât și solicitantul, \
        trebuie să întocmească și să gestioneze o multitudine de documente și termene. \
        E-urban.ro simplifică și organizeză aceste procese pentru a face viața mai ușoară atât contribuabilului, cât și funcționarului public. ",
        bgTitle: "bg-green-100", colorTitle: "text-zinc-700",
        bg: "bg-green-50", color: "text-green-900"
    },
    {
        title: "Cu ce mă ajută, de fapt, e-urban.ro?",
        leading: "EFICIENȚĂ, ACURARATEȚE ȘI BUNĂ ORGANIZARE! ",
        desc: "Folosind noul nostru serviciu web e-urban.ro, veți avea o evidență clară a \
        cererilor de eliberare/prelungire a certificatelor de urbanism, a certificatelor de urbanism, a cererilor de \
        eliberare/prelungire ale autorizațiilor de construire, avizelor, documentelor depuse de solicitanți, inclusiv a fișierelor \
        transmise, comunicărilor de începere/încheiere a lucrărilor și a proceselor verbale.  ",
        bgTitle: "bg-blue-100", colorTitle: "text-black",
        bg: "bg-blue-50", color: "text-blue-900"
    },
    {
        title: "Doar atât face e-urban.ro?",
        leading: "NU. DE FAPT FACE... APROAPE DE TOATE:",
        desc: <>...generează toate documentele gata pregătite pentru tipărire, face automat 
        rapoartele lunare pentru Inspecția de Stat în Construcții și site-ul instituției, indică dacă un document a expirat sau 
        este pe cale să expire, permite trasmiterea de cereri și atașarea de documente online prin profilul personal
       <div className='text-red-600 font-extrabold pt-2'>IMPORTANT! {' '}
            <span className='text-zinc-500 font-blod '> Odată cu intrarea în vigoare a Noului Cod al Construcțiilor și Amenajării 
            Teritoriului, ne angajăm ferm să implementăm în servicul nostru web, e-urban.ro, orice modificare adusă de lege, în cel mai scurt timp 
            posibil !
            (max. 10-15 zile: termene, formulare, mod de lucru)  </span>
        </div>
        </>,
        bgTitle: "bg-purple-100", colorTitle: "text-slate-900",
        bg: "bg-purple-50", color: "text-indigo-900"
    },
    {
        title: "Prea frumos să fie adevarat. Oare chiar veți livra ce promiteți?",
        leading: "GARANȚIA CELOR PESTE 40 DE CLIENȚI MULȚUMIȚI CARE BENEFICIAZĂ DE SERVICIILE NOASTE WEB DE PESTE 7 ANI! ",
        desc: "Modelul nostru de business este unic și presupune inovare. Soluțiile noastre \"smart\" au fost și sunt \
        dezvoltate în strânsă legătură cu clienții existenți, analizând modul de lucru al acestora prin discuții \
        directe și oneste. Transpunem practic modul de lucru actual într-un format mult mai eficient!",
        bgTitle: "bg-yellow-100", colorTitle: "text-green-900",
        bg: "bg-yellow-50", color: "text-green-800"
    }
]

const PACKAGES = [
    {
        title: "ABONAMENT SEMESTRIAL",
        price: "400",
        min: 6,
        unit: "lei/lună",
        desc: "plătești în două tranșe semestriale, a câte 2400 lei fiecare"
    },
    {
        title: "ABONAMENT ANUAL",
        price: "350",
        min: 12,
        unit: "lei/lună",
        desc: "scapi de griji un an cu o singură plată de 4000 lei. Da. 4000, nu 4200",
        featured: true
    },
    {
        title: "MODULUL PROFIL CLIENT",
        price: "1000",
        min: 12,
        unit: "lei/an",
        desc: "serviciu suplimentar oferit doar pentru pachetul \"Abonament anual\""
    }
]

export default function Oferta() {
    return (
        <div className="container px-4 mx-auto mb-44">
            <div className="flex flex-wrap -mx-4">
                <div className='w-full text-left text-3xl md:text-4xl font-bold text-black pb-0 md:pb-5 my-5 px-5'>
                    Oferta E-URBAN.RO
                </div>
                {/* card */}
                {ITEMS.map((e, i) => (
                    <div 
                        className={`w-full rounded-lg my-2 md:mx-4 shadow-xl mx-2 ${e.bg} ${e.color}`}
                        key={i}
                    >
                        <div className={'rounded-t-lg p-3 px-4 ' + e.bgTitle}>
                            <div className={'font-bold text-2sm md:text-2xl text-left sm:text-justify '+ e.colorTitle}>
                                {e.title}
                            </div>
                        </div>
                        <div className= 'rounded-b-lg p-5 font-semibold text-xs md:text-md  text-left md:text-justify '>
                            <div className='font-extrabold text-xs md:text-base pb-2'>{e.leading}</div>
                            <div className='font-light text-zinc-900 text-xs md:text-base'>{e.desc}</div>
                        </div>
                    </div>
                ))}
                {/* pachete */}
                <div className='w-full py-10 text-center'>
                <div className='w-full text-center text-3xl md:text-4xl font-extrabold text-slate-600 pb-0 md:pb-5'>
                    ABONAMENTE
                </div>
                </div>

                { PACKAGES.map( (e,i) => (
                <div className='w-full md:w-1/3 px-10 md:px-0 ' key={i}>
                <div className={'bg-slate-50 rounded-2xl mx-5 p-5 shadow-2xl mb-3 border-2 border-grey-800'}>
                    <div className='font-bold md:font-extrabold text-normal md:text-2xl text-blue-800 text-center'>
                            {e.title}
                    </div>
                    <div className='font-extrabold text-2xl md:text-6xl text-red-700 text-center pt-5'>
                           {e.price}
                    </div>
                    <div className='font-semilight text-xs md:text-sm text-zinc-500 text-center'>
                        {e.unit}
                    </div>
                    <div className='text-center px-12 py-3'>
                        
                    <hr className=''/>
                    </div>
                    <div className='font-semilight text-xs md:text-sm text-zinc-500 text-center'>
                        {e.desc}
                    </div>
                </div>

            </div>
                ))}
                <div className='w-full text-center text-sm text-slate-500 px-5 md:px-10'>
                    <hr className='border-slate-500 m-10'/>
                    Bineînțeles că ne găsiți pe E-LICITATE.RO.<br/> Deoarce tratăm cu maximă responsabilitate colaborările noastre și pentru că vom deveni partenerul Dvs. legal ce procesează și stochează date personale, va fi absolut necesar să încheiem un contract în formă scrisă.
                </div>
               


            </div>

        </div>
    )
}
